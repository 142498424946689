
import Vue, { PropOptions } from 'vue';

export default Vue.extend({
  name: 'StatusBilling',
  props: {
    status: {
      type: String as () => string,
      required: true,
    } as PropOptions,
  },
  computed: {
    getStatus(): string {
      if (this.status === 'paid') {
        return 'paid';
      }

      if (this.status === 'pending') {
        return 'pending';
      }

      return 'overdue';
    },
  },
});
