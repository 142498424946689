
import mixins from 'vue-typed-mixins';
import { PropOptions } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { SweetAlertResult } from 'sweetalert2';

import CardAddBox from '@/resources/billing/components/CardAddBox.vue';

import PaymentMethodsService from '@/common/services/PaymentMethodsService';
import StatesAndCitiesService from '@/common/services/StatesAndCitiesService';
import PosCodeService from '@/common/services/PostCodeService';
import NotificationMixin from '@/mixins/NotificationMixin';
import { SavePaymentMethodAdapter } from '@/resources/billing/adapters';
import {
  CardManagerInformation, VForm, DateFormat, PaymentMethod, State, AddressCommon,
} from '@/types';
import dateFormat from '@/common/filters/DateFormat';
import numberRemoveFormat from '@/common/filters/NumberRemoveFormat';
import { voidPaymentMethods } from '@/resources/billing/mocks';

type DeleteMethod = {
  vindiId: number;
  index: number;
};

export default mixins(NotificationMixin).extend({
  name: 'BillingInformationManagerSection',

  components: {
    CardAddBox,
  },

  props: {
    paymentMethods: {
      type: Object as () => PaymentMethod,
      default: () => ({}),
    } as PropOptions,
  },

  data: () => ({
    payload: { ...voidPaymentMethods } as PaymentMethod,
    federativeUnits: [],
    variavel: {},
    useTheSameAddressToInvoice: false,
  }),

  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
    }),
  },

  watch: {
    token(token: string) {
      if (token && this.userLoggedIn) {
        this.getStates();
      }
    },

    useTheSameAddressToInvoice(value: boolean): void {
      if (value) {
        this.copyAddress();
      }
    },
  },

  created() {
    if (this.token && this.userLoggedIn) {
      this.getStates();
    }

    this.payload = { ...this.payload, ...this.paymentMethods };
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    async getStates(): Promise<void> {
      try {
        this.handleLoading(true);

        this.federativeUnits = (
          await StatesAndCitiesService.getStates()
        ).data.data.map((state: State) => state.uf);
      } catch (e) {
        this.errorToast({
          text: this.$t('errorStates') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    async deleteCreditCard({ vindiId, index }: DeleteMethod): Promise<void> {
      try {
        const { isConfirmed }: SweetAlertResult = await this.confirmDelete({
          title: this.$t('billing.sections.billingInformation.manager.actions.deleteCreditCard.title') as string,
          text: this.$t('billing.sections.billingInformation.manager.actions.deleteCreditCard.message') as string,
        });

        if (!isConfirmed) return;

        this.handleLoading(true);

        await PaymentMethodsService.deletePaymentMethod(vindiId);

        this.payload.paymentMethods.splice(index, 1);

        this.successToast({
          text: this.$t('billing.sections.billingInformation.manager.actions.deleteCreditCard.msgSuccess') as string,
        });
      } catch (e) {
        this.errorToast({
          text: this.$t('billing.sections.billingInformation.manager.actions.deleteCreditCard.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    copyAddress(): void {
      const {
        street,
        additionalDetails,
        number,
        zipCode,
        city,
        neighborhood,
        state,
        country,
      } = this.payload.billing;

      this.payload.invoice = {
        ...this.payload.invoice,
        street,
        additionalDetails,
        number,
        zipCode,
        city,
        neighborhood,
        state,
        country,
      };
    },

    async findPostCode(addressType: string, zipCode: string): Promise<void> {
      try {
        if (zipCode.length === 10) {
          const { data } = await PosCodeService.find(numberRemoveFormat(zipCode));

          const addessFields: AddressCommon = {
            zipCode,
            street: data.logradouro,
            number: '',
            additionalDetails: data.complemento,
            neighborhood: data.bairro,
            city: data.localidade,
            state: data.uf,
            country: this.$t('billing.sections.billingInformation.manager.countryName') as string,
          };

          // @ts-ignore
          this.payload[addressType] = {
            // @ts-ignore
            ...this.payload[addressType],
            ...addessFields,
          };
        }
      } catch (e) {
        const addessFields: AddressCommon = {
          zipCode: '',
          street: '',
          number: '',
          additionalDetails: '',
          neighborhood: '',
          city: '',
          state: '',
          country: '',
        };
        // @ts-ignore
        this.payload[addressType] = {
          // @ts-ignore
          ...this.payload[addressType],
          ...addessFields,
        };
      }
    },

    formatDates(cards: Array<CardManagerInformation>, format: DateFormat) {
      return cards.map((card) => ({
        ...card,
        expiresIn: dateFormat(card.expiresIn, format),
      }));
    },

    async save(): Promise<void> {
      const form: any = this.$refs.form as VForm;
      const valid: boolean = await form.validate();

      if (valid) {
        const body = SavePaymentMethodAdapter({
          ...this.paymentMethods,
          ...this.payload,
        });

        if (Object.keys(this.paymentMethods).length) {
          await this.updatePaymentMethod(body);
          return;
        }

        await this.createPaymentMethod(body);
      }
    },

    async createPaymentMethod(paymentMethod: any): Promise<void> {
      try {
        this.handleLoading(true);

        await PaymentMethodsService.createPaymentMethod(paymentMethod);

        this.successToast({
          text: this.$t('billing.sections.billingInformation.manager.actions.save.msgSuccess') as string,
        });

        await this.$router.push({ name: 'billing-information' });
      } catch (e) {
        this.errorToast({
          text: this.$t('billing.sections.billingInformation.manager.actions.save.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    async updatePaymentMethod(paymentMethod: any): Promise<void> {
      try {
        this.handleLoading(true);

        await PaymentMethodsService.updatePaymentMethod(paymentMethod);

        this.successToast({
          text: this.$t('billing.sections.billingInformation.manager.actions.update.msgSuccess') as string,
        });

        await this.$router.push({ name: 'billing-information' });
      } catch (e) {
        this.errorToast({
          text: this.$t('billing.sections.billingInformation.manager.actions.update.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },
  },
});
