import axios from '@/common/http';

class BillingService {
  private service: typeof axios = axios;

  private baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/api/v1/bills`;

  public listBills() {
    return this.service.get(this.baseUrl);
  }

  public getBilling(billId: number) {
    return this.service.get(`${this.baseUrl}/bill/${billId}`);
  }

  public getBillet(billId: number) {
    return this.service.get(`${this.baseUrl}/payment/${billId}`);
  }

  public listBillsByPeriod(period: string) {
    return this.service.get(`${this.baseUrl}/filter/${period}`);
  }

  public getBillProducts(billId: number) {
    return this.service.get(`${this.baseUrl}/products/${billId}`);
  }

  public getCharges(billId: number) {
    return this.service.get(`${this.baseUrl}/charges/${billId}`);
  }
}

export default new BillingService();
