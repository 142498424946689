import axios, { AxiosInstance } from '@/common/http';

class PostCodeService {
  private service: AxiosInstance;

  private baseUrl = `${process.env.VUE_APP_POSTCODE_GATEWAY}`;

  constructor() {
    this.service = axios.create({
      transformRequest: (_, headers) => {
        // eslint-disable-next-line no-param-reassign
        delete headers.common;
      },
    });
  }

  public find(zipCode: string) {
    return this.service.get(`${this.baseUrl}/${zipCode}/json`);
  }
}

export default new PostCodeService();
