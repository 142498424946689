var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "r-custom-table--wrapper" }, [
    _c(
      "table",
      {
        staticClass: "r-custom-table",
        attrs: { id: _vm.id, cellspacing: "0" },
      },
      [
        _c("thead", { staticClass: "r-custom-table__heading" }, [
          _c(
            "tr",
            [
              _vm._l(_vm.columnsData.alias, function (column, index) {
                return [
                  _vm._t(
                    "heading",
                    function () {
                      return [
                        _c("th", { key: `${column}-${index}` }, [
                          _c("div", [
                            _vm._v(" " + _vm._s(column) + " "),
                            !_vm.sortable
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "sort",
                                    class: {
                                      selected:
                                        _vm.currentSort ===
                                        _vm.columnsData.columnsToShow[index],
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "up",
                                      on: {
                                        click: function ($event) {
                                          return _vm.sort(
                                            "asc",
                                            _vm.columnsData.columnsToShow[index]
                                          )
                                        },
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "down",
                                      on: {
                                        click: function ($event) {
                                          return _vm.sort(
                                            "desc",
                                            _vm.columnsData.columnsToShow[index]
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    },
                    { column: column, sort: _vm.sort }
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
        _c(
          "tbody",
          { staticClass: "r-custom-table__body" },
          [
            _vm.data.length && !_vm.loading
              ? [
                  _vm._l(_vm.sortedList, function (item, index) {
                    return [
                      _c(
                        "tr",
                        {
                          key: `row-${index}`,
                          staticClass: "r-custom-table__row",
                          class: `${
                            _vm.highlightRow === index ? "highlighted" : ""
                          }
                ${_vm.returnClassName(item.legalStatus)}`,
                        },
                        [
                          _vm._l(
                            _vm.columnsData.columnsToShow,
                            function (column, columnIndex) {
                              return [
                                _vm._t(
                                  "columns",
                                  function () {
                                    return [
                                      column === "simpleName"
                                        ? _c(
                                            "td",
                                            {
                                              key: `${index}-${columnIndex}`,
                                              staticClass:
                                                "name-column pr-2 pl-3",
                                            },
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "top",
                                                    trigger: "hover",
                                                    "popper-class":
                                                      "popover-blue",
                                                  },
                                                },
                                                [
                                                  item.type == ".xml"
                                                    ? _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.viewFile(
                                                                  $event,
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v(" XML ")]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.viewFile(
                                                                  $event,
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v(" PDF ")]
                                                        ),
                                                      ])
                                                    : _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.viewFile(
                                                                  $event,
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v(" PDF ")]
                                                        ),
                                                      ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "13px",
                                                      },
                                                      attrs: {
                                                        slot: "reference",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [
                                                      item.origin == "SITE"
                                                        ? _c("i", {
                                                            staticClass:
                                                              "icon-attach",
                                                          })
                                                        : _c("i", {
                                                            staticClass:
                                                              "icon-email",
                                                          }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip.top",
                                                      value: _vm.splitName(
                                                        item[column]
                                                      ),
                                                      expression:
                                                        "splitName(item[column])",
                                                      modifiers: { top: true },
                                                    },
                                                  ],
                                                  staticClass: "item",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.splitName(
                                                          item[column]
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : column === "classification"
                                        ? _c(
                                            "td",
                                            {
                                              key: `${index}-${columnIndex}`,
                                              staticClass: "classification",
                                            },
                                            [
                                              item.classification ==
                                              "/error-classification"
                                                ? _c(
                                                    "span",
                                                    {
                                                      attrs: { type: "error" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              `labelClassificacao${item.classification
                                                                .replace(
                                                                  /(\b[a-z](?!\s))/g,
                                                                  function (x) {
                                                                    return x.toUpperCase()
                                                                  }
                                                                )
                                                                .replace(
                                                                  /[\/\-]/gi,
                                                                  ""
                                                                )}`
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : !item.processing
                                                ? _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        type: "success",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              `labelClassificacao${item.classification
                                                                .replace(
                                                                  /(\b[a-z](?!\s))/g,
                                                                  function (x) {
                                                                    return x.toUpperCase()
                                                                  }
                                                                )
                                                                .replace(
                                                                  /\//gi,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /\_/gi,
                                                                  ""
                                                                )}`
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    { attrs: { type: "info" } },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "labelSemClassificacao"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          )
                                        : column === "message"
                                        ? _c(
                                            "td",
                                            {
                                              key: `${index}-${columnIndex}`,
                                              staticClass: "status-column",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "status alert-margin-negativa",
                                                  class: _vm.returnStatusClass(
                                                    item.processStatus
                                                  ),
                                                  attrs: { role: "alert" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item[column]) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : column === "createdAt"
                                        ? _c(
                                            "td",
                                            { key: `${index}-${columnIndex}` },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .$moment(item.createdAt)
                                                      .format(
                                                        "DD/MM/YYYY HH:mm"
                                                      )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column === "liquidValue" &&
                                          item.liquidValue !== undefined
                                        ? _c(
                                            "td",
                                            { key: `${index}-${columnIndex}` },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatCurrency(
                                                      item.liquidValue
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                  { item: item, column: column, index: index }
                                ),
                                columnIndex == 0 && _vm.expandable
                                  ? _c(
                                      "td",
                                      { key: `${index}-${columnIndex}` },
                                      [
                                        item[_vm.expandableItems].length
                                          ? _c("i", {
                                              class: `icon-box-${
                                                typeof _vm.openClose[index] ===
                                                "undefined"
                                                  ? "plus"
                                                  : _vm.openClose[index]
                                              }`,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.retractableLine(
                                                    index
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          " " + _vm._s(item[column]) + " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }
                          ),
                          _vm._t("actions", null, { item: item, index: index }),
                        ],
                        2
                      ),
                      _vm._t("expandable", null, {
                        item: item,
                        index: index,
                        classLine: {
                          "cel-expandable": true,
                          expanded: _vm.expandLine[index],
                        },
                      }),
                    ]
                  }),
                ]
              : _vm.loading
              ? _vm._l(10, function (index) {
                  return _c(
                    "tr",
                    {
                      key: `loading-${index}`,
                      staticClass: "r-custom-table__loading",
                    },
                    _vm._l(
                      _vm.columnsData.columnsToShow,
                      function (item, columnIndex) {
                        return _c(
                          "td",
                          { key: `${item}-${index}-${columnIndex}` },
                          [_c("p", [_vm._v(" " + _vm._s(item) + " ")])]
                        )
                      }
                    ),
                    0
                  )
                })
              : [
                  _c("tr", { staticClass: "r-custom-table__empty" }, [
                    _c(
                      "td",
                      { attrs: { colspan: _vm.columnsData.alias.length } },
                      [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: require("@/common/assets/images/tabelaVazia.svg"),
                            },
                          }),
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("table.mainLabelEmptyFlow"))),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("table.secondaryLabelEmptyFlow"))
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ],
            _vm.data.length && !_vm.loading
              ? _vm._t("sumary", null, { data: _vm.data })
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }