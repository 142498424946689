
import moment from 'moment';
import { showDateToUser } from '@roit/roit-date';
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';
import StatusBilling from '@/resources/billing/components/StatusBilling.vue';
import { BillingItem } from '@/types';
import NotificationMixin from '@/mixins/NotificationMixin';
import BillingService from '@/common/services/BillingService';
import CnpjFormat from '@/common/filters/CnpjFormat';

export default mixins(NotificationMixin).extend({
  name: 'BillingCompaniesTable',
  components: {
    StatusBilling,
  },
  filters: {
    pointedValue(value: number): string {
      return value?.toString().replaceAll('.', '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') ?? value;
    },
    toMoney(value: string): string {
      return parseFloat(value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
    periodRange(value: any | null): string {
      if (value) {
        const format = (date?: string): string | null => (date ? moment(date).format('DD/MM/YYYY') : null);
        return `${format(value?.start_at)} a ${format(value?.end_at)}`;
      }
      return value;
    },
    CnpjFormat,
  },
  props: {
    id: {
      type: String,
      required: true,
    },

    rows: {
      type: Array,
      required: true,
    },

    billid: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      allItemsSelected: false,
      selectedItem: [] as Array<string>,
      header: {
        alias: [
          this.$t('billing.sections.billing.businessGroupTable.headers.cnpj'),
          this.$t('billing.sections.billing.businessGroupTable.headers.plan'),
          this.$t('billing.sections.billing.businessGroupTable.headers.period'),
          this.$t('billing.sections.billing.businessGroupTable.headers.emission'),
          this.$t('billing.sections.billing.businessGroupTable.headers.totalValue'),
          this.$t('billing.sections.billing.businessGroupTable.headers.dueDate'),
          this.$t('billing.sections.billing.businessGroupTable.headers.status'),
        ],
        columnsToShow: [
          'cnpj',
          'plan',
          'period',
          'emission',
          'consumed',
          'total_value',
          'due_date',
          'status',
        ],
      },
    };
  },
  watch: {
    allItemsSelected(value: boolean): void {
      if (value) {
        this.selectedItem = this.rows.map((row: any) => row.id);
        this.$emit('billdetail:change-all-summary', this.rows);
      } else {
        this.selectedItem = [];
        this.$emit('billdetail:change-all-summary', []);
      }
    },
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    showISODate(date: string): string {
      const formated = showDateToUser(date);
      if (!formated) return date;
      return formated;
    },
    showBill(billItem: BillingItem) {
      this.$emit('billdetail:view', billItem);
    },
    showreceiptPayment(billItem: BillingItem) {
      this.$emit('billdetail:receipt', billItem);
    },
    summaryForPayment(billItem: BillingItem) {
      if (!this.selectedItem.filter((id) => Number(id) === billItem.id).length) {
        this.$emit('billdetail:add-summary', billItem);
      } else {
        this.$emit('billdetail:remove-summary', billItem);
      }
    },
    async makePayment(billItem: BillingItem): Promise<void> {
      try {
        this.handleLoading(true);
        const billId = billItem.id;
        const billet = (await BillingService.getBillet(billId)).data.data;

        if (billet.data) {
          window.open(billet.data, '_blank');
        } else {
          this.warningToast({
            title: this.$t('warning') as string,
            text: this.$t('billing.sections.billing.makePayment.msgWarning') as string,
          });
        }
      } catch (e) {
        this.errorToast({
          title: this.$t('error') as string,
          text: this.$t('billing.sections.billing.makePayment.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },
  },
});
