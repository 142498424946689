/* eslint-disable */
import { PaymentMethod } from '@/types';

export default {
  BANK: [
    {
      companyGroup: 'COMPANY GROUP EXAMPLE',
      billId: 1234567,
      billItems: [
        {
          id: 9876543,
          cnpj: '11.222.333/4444-55',
          company: 'EXAMPLE COMPANY LTDA',
          period: {
            id: 0,
            billing_at: '2020-10-30T08:09:56.000-03:00',
            cycle: 0,
            start_at: '2020-10-28T17:06:01.000-03:00',
            end_at: '2020-10-30T08:09:56.000-03:00',
            duration: 0,
          },
          contracted: '0 a 99999',
          consumed: 0,
          surplus: 0,
          unit_value: 0,
          surplus_value: 0,
          total_value: '100.0',
        },
      ],
    },
  ],
};

export const paymentMethods = {
  billingAddress: {
    name: 'Marcelo Silva',
    address: 'Rua Teste Teste Teste',
    number: '333',
    district: 'Bela Vista',
    postCode: '80.000-000',
    uf: 'São Paulo/SP - Brasil',
  },
  invoiceAddress: {
    name: 'Marcelo Silva',
    company: 'Empresa Teste LTDA',
    email: 'email.teste@teste.com',
    address: 'Rua Teste Teste Teste',
    number: '333',
    district: 'Bela Vista',
    postCode: '80.000-000',
    uf: 'São Paulo/SP - Brasil',
  },
  cards: [
    {
      name: 'Marcelo Silva',
      expiresIn: '05/2023',
      lastFourDigits: '6698',
      flag: 'mastercard',
    },
    {
      name: 'João Meireles de Oliveira',
      expiresIn: '04/2024',
      lastFourDigits: '1234',
      flag: 'visa',
    },
  ],
};

export const listPaymentMethods = {
  id: 0,
  payment_method: [
    {
      holder_name: 'JOSE DA SILVA',
      payment_company_code: 'mastercard',
      card_expiration: '2026-01-01T00:59:59.000-02:00',
      card_number: '5167456666661773',
      card_cvv: '666',
      is_main: 'true',
    },
  ],
  charge_address: {
    company: '3D PROJETOS E ASSESSORIA',
    email: 'email@email.com',
    street: 'Avenida dos Alfeneiros',
    number: '04',
    additional_details: 'Apartamento 66',
    zipcode: '58400012',
    neighborhood: 'Centro',
    city: 'Campina Grande',
    state: 'PB',
    country: 'Brasil',
  },
  bill_address: {
    first_name: 'Marcelo',
    last_name: 'Silva',
    street: 'Rua Teste Teste Teste',
    number: '107',
    additional_details: 'Apartamento 66',
    zipcode: '58400012',
    neighborhood: 'Centro',
    city: 'Campina Grande',
    state: 'PB',
    country: 'Brasil',
  },
};

export const voidPaymentMethods: PaymentMethod = {
  id: '',
  userId: '',
  paymentMethods: [],
  billing: {
    company: '',
    email: '',
    zipCode: '',
    street: '',
    number: '',
    additionalDetails: '',
    neighborhood: '',
    city: '',
    state: '',
    country: '',
  },
  invoice: {
    firstName: '',
    lastName: '',
    zipCode: '',
    street: '',
    number: '',
    additionalDetails: '',
    neighborhood: '',
    city: '',
    state: '',
    country: '',
  },
};


export const charge =   {
  id: 0,
  amount: 'any_value',
  status: 'any_status',
  due_at: 'any_date',
  paid_at: 'any_date',
  installments: 1,
  attempt_count: 1,
  next_attempt: 'any_property',
  print_url: 'any_property',
  created_at: 'any_date',
  updated_at: 'any_date',
  last_transaction: {
    id: 0,
    transaction_type: 'any_property',
    status: 'any_status',
    amount: 'any_value',
    installments: 1,
    gateway_message: 'any_property',
    gateway_response_code: 'any_property',
    gateway_authorization: 'any_property',
    gateway_transaction_id: 'any_property',
    gateway_response_fields: {
      nsu: 'any_property'
    },
    fraud_detector_score: 'any_property',
    fraud_detector_status: 'any_property',
    fraud_detector_id: 'any_property',
    created_at: 'any_date',
    gateway: {
      id: 0,
      connector: 'any_property'
    },
    payment_profile: {
      id: 0,
      holder_name: 'TESTE',
      registry_code: 'any_property',
      bank_branch: 'any_property',
      bank_account: 'any_property',
      card_expiration: 'any_date',
      allow_as_fallback: 'any_property',
      card_number_first_six: 'any_property',
      card_number_last_four: 'any_property',
      renewed_card: {
        card_number_last_four: 'any_property',
        card_expiration: 'any_property'
      },
      card_renewed_at: 'any_property',
      token: 'any_property',
      created_at: 'any_date',
      payment_company: {
        id: 0,
        name: 'any_value',
        code: 'any_value'
      }
    }
  },
  payment_method: {
    id: 0,
    public_name: 'any_value',
    name: 'any_value',
    code: 'any_value',
    type: 'any_value'
  }
}