
import Vue from 'vue';
import moment from 'moment';
import { BillingCharge } from '@/types';

export default Vue.extend({
  name: 'ReceiptPayment',
  props: {
    show: {
      type: Boolean as () => boolean,
      default: false,
    },
    charge: {
      type: Object as () => BillingCharge,
      required: true,
    },
  },
  computed: {
    displayModal: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean): void {
        this.$emit('update:show', value);
      },
    },
    amountFixed: {
      get(): string {
        return Number(this.charge.amount).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      },
    },
    cardExpirationDate: {
      get(): string {
        return moment(this.charge.last_transaction.payment_profile.card_expiration).format('MM/YYYY');
      },
    },
    paidAtDate: {
      get(): string {
        return moment(this.charge.paid_at).format('DD MMM YYYY, HH:mm');
      },
    },
    installments: {
      get(): string | number {
        if (this.charge.installments === 1) {
          return 'À vista';
        }
        return this.charge.installments;
      },
    },

  },
  methods: {
    printRecepit() {
      window.print();
    },

  },
});
