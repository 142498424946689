var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("custom-table", {
    attrs: {
      id: _vm.id,
      "columns-data": _vm.header,
      data: _vm.rows,
      "row-length": _vm.rows.length,
      paginate: !!_vm.rows.length,
      sortable: true,
    },
    on: {
      currPage: function ($event) {
        return _vm.$emit("currentPage", $event)
      },
      pageSize: function ($event) {
        return _vm.$emit("currentPage", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "heading",
        fn: function ({ column }) {
          return [
            column === "checkbox"
              ? _c("th", { staticClass: "text-center" }, [
                  _c(
                    "div",
                    { staticClass: "d-inline-flex" },
                    [
                      _c("Checkbox", {
                        attrs: { binary: true },
                        model: {
                          value: _vm.allItemsSelected,
                          callback: function ($$v) {
                            _vm.allItemsSelected = $$v
                          },
                          expression: "allItemsSelected",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        },
      },
      {
        key: "columns",
        fn: function ({ item, column }) {
          return [
            column === "cnpj"
              ? _c("td", [
                  _vm._v(" " + _vm._s(_vm._f("cnpjFormat")(item.cnpj)) + " "),
                  _c("br"),
                  _c("span", { staticClass: "bottom-text" }, [
                    _vm._v(_vm._s(item.company)),
                  ]),
                ])
              : _vm._e(),
            column === "plan"
              ? _c("td", [_vm._v(" " + _vm._s(item.plan) + " "), _c("br")])
              : _vm._e(),
            column === "period"
              ? _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm._f("periodRange")(item.period)) + " "
                  ),
                ])
              : _vm._e(),
            column === "emission"
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.showISODate(
                          item.period ? item.period.billing_at : ""
                        )
                      ) +
                      " "
                  ),
                  _c("br"),
                ])
              : _vm._e(),
            column === "total_value"
              ? _c("td", { staticClass: "text-left" }, [
                  _vm._v(" " + _vm._s(_vm._f("toMoney")(item.amount)) + " "),
                  _c("span", [
                    item.payment_method === "credit_card"
                      ? _c("i", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.top",
                              value: _vm.$t(
                                "billing.sections.billing.businessGroupTable.text.viewCard"
                              ),
                              expression:
                                "$t('billing.sections.billing.businessGroupTable.text.viewCard')",
                              modifiers: { top: true },
                            },
                          ],
                          staticClass: "icon-payment icon-r-credit-card",
                          attrs: { alt: "Payment Icon" },
                        })
                      : _vm._e(),
                    item.payment_method === "invoice"
                      ? _c("i", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.top",
                              value: _vm.$t(
                                "billing.sections.billing.businessGroupTable.text.viewInvoice"
                              ),
                              expression:
                                "$t('billing.sections.billing.businessGroupTable.text.viewInvoice')",
                              modifiers: { top: true },
                            },
                          ],
                          staticClass: "icon-payment icon-r-file-invoice",
                          attrs: { alt: "Payment Icon" },
                        })
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            column === "due_date"
              ? _c("td", [
                  _vm._v(" " + _vm._s(_vm.showISODate(item.due_at)) + " "),
                ])
              : _vm._e(),
            column === "status"
              ? _c(
                  "td",
                  [
                    _c("StatusBilling", {
                      attrs: { status: item.status, cnpj: item.cnpj },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "actions",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "actions" }, [
              item.status !== "paid"
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: _vm.$t(
                            "billing.sections.billing.summaryPayment.toPay"
                          ),
                          expression:
                            "$t('billing.sections.billing.summaryPayment.toPay')",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "action",
                      on: {
                        click: function ($event) {
                          return _vm.makePayment(item)
                        },
                      },
                    },
                    [_c("i", { staticClass: "icon-r-coin" })]
                  )
                : _vm._e(),
              item.status === "paid"
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: _vm.$t("billing.sections.billing.viewInvoice"),
                          expression:
                            "$t('billing.sections.billing.viewInvoice')",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "action",
                      on: {
                        click: function ($event) {
                          return _vm.showreceiptPayment(item)
                        },
                      },
                    },
                    [_c("i", { staticClass: "icon-r-file-list" })]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.top",
                      value: _vm.$t(
                        "billing.sections.billing.detailModal.title"
                      ),
                      expression:
                        "$t('billing.sections.billing.detailModal.title')",
                      modifiers: { top: true },
                    },
                  ],
                  staticClass: "action",
                  on: {
                    click: function ($event) {
                      return _vm.showBill(item)
                    },
                  },
                },
                [_c("i", { staticClass: "icon-r-eye" })]
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }