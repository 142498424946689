
import Vue from 'vue';

import LastFourDigitsCreditCard from '@/common/filters/LastFourDigitsCreditCard';
import { Method } from '@/types';

export default Vue.extend({
  name: 'BillingCardPreview',

  filters: {
    LastFourDigitsCreditCard,
  },

  props: {
    method: {
      type: Object as () => Method,
      required: true,
    },
  },
});
