
import Vue from 'vue';
import DateLocale from '@/common/classes/dateLocale';
import DateFormat from '@/common/filters/DateFormat';
import DateValidation from '@/common/assets/plugins/vee-validate/rules/date/date';

export default Vue.extend({
  name: 'HeaderSection',
  data() {
    return {
      ptBR: DateLocale.ptBR,
      date: '',
    };
  },
  watch: {
    date(date: string) {
      const format = 'MM/YYYY';
      if (!date) {
        this.$emit('handleChangeDate');
      } else if (DateValidation(date, format)) {
        this.$emit('handleChangeDate', DateFormat(date, { input: format, output: 'YYYY-MM' }));
      }
    },
  },
});
