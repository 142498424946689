
import Vue from 'vue';

export default Vue.extend({
  name: 'BillingCompaniesTree',
  props: {
    totalItems: {
      type: Number as () => number,
      required: true,
    },
  },
});
