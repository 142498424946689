var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Dialog",
    {
      style: { maxWidth: "none", padding: 0, border: "1px solid gray" },
      attrs: {
        visible: _vm.displayModal,
        modal: true,
        "content-class": "modalReceipt",
        "content-style": {
          overflow: "visible",
          padding: 0,
          boxShadow: "0px 2px 3px #0000003B",
        },
      },
      on: {
        "update:visible": function ($event) {
          _vm.displayModal = $event
        },
      },
    },
    [
      _c("div", { staticClass: "custom-receipt" }, [
        _c("div", { staticClass: "custom-receipt__header" }, [
          _c("img", {
            attrs: {
              src: require("../../../common/assets/images/Logo_roit_bank_azul_dourada.svg"),
              alt: "logo roit",
            },
          }),
          _c(
            "button",
            { staticClass: "print-button", on: { click: _vm.printRecepit } },
            [_c("i", { staticClass: "icon-r-printer" })]
          ),
        ]),
        _c("hr"),
        _c("div", { staticClass: "custom-receipt__title" }, [
          _c(
            "p",
            {
              staticClass:
                "mb-0 font-bold text-uppercase font-size-18 text-darkblue",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("billing.sections.billing.receiptPayment.title")
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "p",
            { staticClass: "mb-0 font-regular font-size-12 text-darkblue" },
            [_vm._v(" " + _vm._s(_vm.paidAtDate) + " ")]
          ),
        ]),
        _c("hr"),
        _c("div", { staticClass: "custom-receipt__value" }, [
          _c(
            "p",
            {
              staticClass:
                "mb-0 font-regular text-uppercase font-size-12 text-gray",
            },
            [_vm._v(" " + _vm._s(_vm.$t("value")) + " ")]
          ),
          _c(
            "p",
            { staticClass: "mb-4 font-bold font-size-18 text-darkblue" },
            [_vm._v(" " + _vm._s(_vm.amountFixed) + " ")]
          ),
          _c(
            "p",
            {
              staticClass:
                "mb-0 font-regular text-uppercase font-size-12 text-gray",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "billing.sections.billing.receiptPayment.paymentMethod"
                    )
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "p",
            { staticClass: "mb-0 font-size-18 font-regular text-lightdark" },
            [_vm._v(" " + _vm._s(_vm.charge.payment_method.name) + " ")]
          ),
        ]),
        _c("hr"),
        _c("div", { staticClass: "custom-receipt__content" }, [
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-0 font-size-14 font-bold text-uppercase text-darkblue",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.receiptPayment.cardInfo.title"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass:
                  "mb-0 font-size-12 font-regular text-uppercase text-gray",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.receiptPayment.cardInfo.number"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              { staticClass: "font-size-18 font-regular text-lightdark" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.charge.last_transaction.payment_profile
                        .card_number_first_six
                    ) +
                    " *** " +
                    _vm._s(
                      _vm.charge.last_transaction.payment_profile
                        .card_number_last_four
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass:
                  "mb-0 font-size-12 font-regular text-uppercase text-gray",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.receiptPayment.cardInfo.flag"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              { staticClass: "font-size-18 font-regular text-lightdark" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.charge.last_transaction.payment_profile
                        .payment_company.name
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass:
                  "mb-0 font-size-12 font-regular text-uppercase text-gray",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.receiptPayment.cardInfo.holder"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              { staticClass: "font-size-18 font-regular text-lightdark" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.charge.last_transaction.payment_profile.holder_name
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass:
                  "mb-0 font-size-12 font-regular text-uppercase text-gray",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.receiptPayment.cardInfo.validity"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              { staticClass: "font-size-18 font-regular text-lightdark" },
              [_vm._v(" " + _vm._s(_vm.cardExpirationDate) + " ")]
            ),
          ]),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-0 font-size-14 font-bold text-uppercase text-darkblue",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.receiptPayment.payment.title"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass:
                  "mb-0 font-size-12 font-regular text-uppercase text-gray",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.receiptPayment.payment.transaction"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              { staticClass: "font-size-18 font-regular text-lightdark" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.charge.last_transaction.gateway_message) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass:
                  "mb-0 font-size-12 font-regular text-uppercase text-gray",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.receiptPayment.payment.installment"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              { staticClass: "font-size-18 font-regular text-lightdark" },
              [_vm._v(" " + _vm._s(_vm.installments) + " ")]
            ),
            _c(
              "p",
              {
                staticClass:
                  "mb-0 font-size-12 font-regular text-uppercase text-gray",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("billing.sections.billing.receiptPayment.attempts")
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              { staticClass: "font-size-18 font-regular text-lightdark" },
              [_vm._v(" " + _vm._s(_vm.charge.attempt_count) + " ")]
            ),
            _c(
              "p",
              {
                staticClass:
                  "mb-0 font-size-12 font-regular text-uppercase text-gray",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.receiptPayment.payment.idTransaction"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "p",
              { staticClass: "font-size-18 font-regular text-lightdark" },
              [_vm._v(" " + _vm._s(_vm.charge.id) + " ")]
            ),
          ]),
        ]),
        _c("hr"),
        _c("div", { staticClass: "custom-receipt__footer" }, [
          _c(
            "p",
            {
              staticClass:
                "mb-0 font-size-12 font-regular text-uppercase text-gray",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "billing.sections.billing.receiptPayment.authorizationCode"
                    )
                  ) +
                  " "
              ),
            ]
          ),
          _c("p", { staticClass: "font-size-18 font-regular text-lightdark" }, [
            _vm._v(
              " " +
                _vm._s(_vm.charge.last_transaction.gateway_transaction_id) +
                " "
            ),
          ]),
        ]),
        _c("img", {
          attrs: {
            src: require("../../../common/assets/images/serrilhado.svg"),
          },
        }),
      ]),
      _c("div", { staticClass: "custom-receipt--aditional-info" }, [
        _c("div", [
          _c(
            "p",
            { staticClass: "font-medium font-size-13 mb-1 text-ligth-gray" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("billing.sections.billing.receiptPayment.callCenter")
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "p",
            { staticClass: "font-regular font-size-13 mb-1 text-ligth-gray" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("billing.sections.billing.receiptPayment.SAC")
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "p",
            { staticClass: "font-regular font-size-13 mb-1 text-ligth-gray" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("billing.sections.billing.receiptPayment.ombudsman")
                  ) +
                  " "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "text-right" }, [
          _c(
            "p",
            { staticClass: "font-medium font-size-13 mb-1 text-ligth-gray" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("billing.sections.billing.receiptPayment.Roit")
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "p",
            { staticClass: "font-medium font-size-13 mb-1 text-ligth-gray" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("billing.sections.billing.receiptPayment.RoitCNPJ")
                  ) +
                  " "
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }