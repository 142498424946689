
import mixins from 'vue-typed-mixins';
import { mapGetters, mapMutations } from 'vuex';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { orderBy } from 'lodash';

import BillingCardPreview from '@/resources/billing/components/BillingCardPreview.vue';
import NotificationMixin from '@/mixins/NotificationMixin';

import PaymentMethodsService from '@/common/services/PaymentMethodsService';
import { ListPaymentMethodAdapter } from '@/resources/billing/adapters';
import CepFormat from '@/common/filters/CepFormat';
import {
  Billing, Invoice, PaymentMethod, Method,
} from '@/types';

export default mixins(NotificationMixin).extend({
  name: 'BillingInformation',

  components: {
    BillingCardPreview,
    PerfectScrollbar,
  },

  filters: {
    getAddress({ street, number, neighborhood }: Invoice): string {
      return `${street}, ${number} ${neighborhood}`;
    },

    getPostCode({ zipCode, city, state }: Billing): string {
      return `${CepFormat(zipCode)} - ${city}/${state}`;
    },
  },

  data: () => ({
    paymentMethods: {} as PaymentMethod,
  }),

  computed: mapGetters({
    token: 'auth/token',
    userLoggedIn: 'auth/userLoggedIn',
  }),

  watch: {
    token(token: string): void {
      if (token && this.userLoggedIn) {
        this.getPaymentMethods();
      }
    },
  },

  mounted() {
    if (this.token && this.userLoggedIn) {
      this.getPaymentMethods();
    }
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    async getPaymentMethods(): Promise<void> {
      try {
        this.handleLoading(true);

        const result = (await PaymentMethodsService.findPaymentMethod()).data.data;

        if (!result) {
          return;
        }

        this.paymentMethods = ListPaymentMethodAdapter(result);
      } catch (e) {
        this.errorToast({
          text: this.$t('billing.sections.billingInformation.actions.list.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    orderPaymentMethods(paymentMethods: Array<Method>): Array<Method> {
      return orderBy(
        paymentMethods, ['isMain', 'flag', 'expiresIn'], ['desc', 'asc', 'asc'],
      );
    },
  },
});
