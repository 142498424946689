
import { PropOptions } from 'vue';
import mixins from 'vue-typed-mixins';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import cardValidator from 'card-validator';
import {
  camelCase, split, pad, startCase, deburr, snakeCase,
} from 'lodash';

import visa from '@/common/assets/images/visa_habilitado.svg';
import mastercard from '@/common/assets/images/mastercard_habilitado.svg';
import americanExpress from '@/common/assets/images/american_express_habilitado.svg';

import NotificationMixin from '@/mixins/NotificationMixin';
import { Method } from '@/types';

export default mixins(NotificationMixin).extend({
  name: 'CardAddBox',

  components: {
    PerfectScrollbar,
  },

  props: {
    value: {
      type: Array as () => Array<Method>,
      required: true,
    } as PropOptions,
  },

  data() {
    return {
      payload: this.value,
      cardNumberMask: [] as Array<string>,
      cardCodeMask: [] as Array<string>,
      cardCodeSize: [] as Array<number>,
      allowedFlags: process.env.VUE_APP_ALLOWED_FLAGS as string,
    };
  },

  computed: {
    flags: () => ({
      visa,
      mastercard,
      americanExpress,
    }),

    numberMask: (): Record<string, string> => ({
      visa: '#### #### #### ####',
      mastercard: '#### #### #### ####',
      americanExpress: '#### ###### #####',
    }),

    getAllowedFlags(): string {
      return this.allowedFlags
        .split(',')
        .map((flag: string) => startCase(flag))
        .join(', ')
        .replace(/, ([^,]*)$/, ` ${this.$t('and')} $1`);
    },
  },

  watch: {
    payload: {
      deep: true,
      handler(methods: Array<Method>): void {
        this.$emit('input', methods);
      },
    },
  },

  methods: {
    addCreditCard() {
      this.payload.push({
        name: '',
        expiresIn: '',
        cardNumber: '',
        flag: '',
        securityCode: '',
        isMain: false,
        showNumber: true,
      });
    },

    methodName(text: string, index: number): void {
      this.payload[index].name = deburr(text).toUpperCase();
    },

    cardFlag(method: Method, index: number): void {
      this.payload[index].flag = '';
      this.cardNumberMask[index] = this.numberMask.visa;
      this.cardCodeMask[index] = '####';
      this.cardCodeSize[index] = 3;
      const allowedFlags = split(this.allowedFlags, ',');

      const { card } = cardValidator.number(method.cardNumber);

      if (method && card && allowedFlags.includes(card.type)) {
        this.cardNumberMask[index] = this.numberMask[camelCase(card.type)];
        this.cardCodeMask[index] = pad('', card.code.size, '#');
        this.cardCodeSize[index] = card.code.size;
        this.payload[index].flag = snakeCase(card.type);
      }
    },

    getFlag(index: number): string {
      return camelCase(this.payload[index].flag);
    },

    deleteMethod(method: Method, index: number): void {
      if (method.vindiId) {
        this.$emit('billing:delete-credit-card', {
          vindiId: method.vindiId,
          index,
        });
        return;
      }

      this.payload.splice(index, 1);
      this.cardNumberMask.splice(index, 1);
      this.cardCodeMask.splice(index, 1);
      this.cardCodeSize.splice(index, 1);
    },

    mainChecked(index: number): void {
      this.payload.forEach((method: Method, i: number) => {
        if (i !== index) this.payload[i].isMain = false;
      });
    },
  },
});
