var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Dialog",
    {
      style: { maxHeight: "none", minHeight: "390px", minWidth: "650px" },
      attrs: { visible: _vm.displayModal, modal: true },
      on: {
        "update:visible": function ($event) {
          _vm.displayModal = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("billing.sections.billing.detailModal.title")) +
                  " "
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("div", [
                _c("div", { staticClass: "amount p-padding-10" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "billing.sections.billing.detailModal.info.amount"
                        )
                      ) +
                      " " +
                      _vm._s(_vm._f("toMoney")(_vm.billingItem.amount)) +
                      " "
                  ),
                ]),
              ]),
              _c("Button", {
                ref: "btnClose",
                staticClass: "outlined p-button-raised",
                attrs: { label: _vm.$t("cancel") },
                on: {
                  click: function ($event) {
                    _vm.displayModal = false
                  },
                },
              }),
              _vm.billingItem.status !== "paid"
                ? _c("Button", {
                    ref: "pay",
                    staticClass: "p-button-raised p-button-raised",
                    attrs: { label: _vm.$t("pay"), disabled: _vm.isDisabled },
                    on: {
                      click: function ($event) {
                        return _vm.makePayment(_vm.billingItem)
                      },
                    },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      [
        _c("div", { staticClass: "billing-detail-box" }, [
          _c("div", { staticClass: "p-grid" }, [
            _c("div", { staticClass: "p-md-4 p-padding-10" }, [
              _c("p", { staticClass: "fs12 grey300 p-margin-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.businessGroupTable.headers.cnpj"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "fs14 p-margin-0 p-bold" }, [
                _vm._v(
                  " " + _vm._s(_vm._f("cnpjFormat")(_vm.billingItem.cnpj)) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "p-md-4 p-padding-10" }, [
              _c("p", { staticClass: "fs12 grey300 p-margin-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.detailModal.info.company"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "fs14 p-margin-0 p-bold" }, [
                _vm._v(" " + _vm._s(_vm.billingItem.company) + " "),
              ]),
            ]),
            _c("div", { staticClass: "p-md-4 p-padding-10" }, [
              _c("p", { staticClass: "fs12 grey300 p-margin-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.businessGroupTable.headers.plan"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "fs14 p-margin-0 p-bold" }, [
                _vm._v(" " + _vm._s(_vm.billingItem.plan) + " "),
              ]),
            ]),
            _c("div", { staticClass: "p-md-4 p-padding-10" }, [
              _c("p", { staticClass: "fs12 grey300 p-margin-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.businessGroupTable.headers.period"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "fs14 p-margin-0 p-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("periodRange")(_vm.billingItem.period)) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "p-md-3 p-padding-10" }, [
              _c("p", { staticClass: "fs12 grey300 p-margin-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.detailModal.info.releaseDate"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "fs14 p-margin-0 p-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.showISODate(
                        _vm.billingItem.period
                          ? _vm.billingItem.period.billing_at
                          : ""
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "p-md-2 p-padding-10" }, [
              _c("p", { staticClass: "fs12 grey300 p-margin-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.detailModal.info.dueDate"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "fs14 p-margin-0 p-bold" }, [
                _vm._v(
                  " " + _vm._s(_vm.showISODate(_vm.billingItem.due_at)) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "p-md-3 p-padding-10" }, [
              _c("p", { staticClass: "fs12 grey300 p-margin-0 status" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "billing.sections.billing.businessGroupTable.headers.status"
                      )
                    ) +
                    " "
                ),
              ]),
              _vm.billingItem.status !== "pending"
                ? _c("div", [
                    _c(
                      "p",
                      { staticClass: "fs13 p-md-20 status" },
                      [
                        _c("StatusBilling", {
                          attrs: {
                            status: _vm.billingItem.status,
                            cnpj: _vm.billingItem.cnpj,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _c("div", [
                    _c(
                      "p",
                      { staticClass: "fs10 p-md-30 status" },
                      [
                        _c("StatusBilling", {
                          attrs: {
                            status: _vm.billingItem.status,
                            cnpj: _vm.billingItem.cnpj,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
            ]),
          ]),
        ]),
      ],
      [
        _c("custom-table", {
          ref: "detailTable",
          attrs: {
            "columns-data": _vm.header,
            data: _vm.rowsData,
            sortable: true,
          },
          scopedSlots: _vm._u([
            {
              key: "columns",
              fn: function ({ item, column }) {
                return [
                  column === "product"
                    ? _c("td", [_vm._v(" " + _vm._s(item.productName) + " ")])
                    : _vm._e(),
                  column === "consumed"
                    ? _c("td", { staticClass: "table-data" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("pointedValue")(item.quantity)) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  column === "price"
                    ? _c("td", [
                        _vm._v(
                          " " + _vm._s(_vm._f("toMoney")(item.unitPrice)) + " "
                        ),
                      ])
                    : _vm._e(),
                  column === "overage_price"
                    ? _c("td", { staticClass: "table-data" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("toMoney")(item.overagePrice)) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  column === "minimumValue"
                    ? _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("toMoney")(item.minimumValue)) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  column === "amount"
                    ? _c("td", [
                        _vm._v(
                          " " + _vm._s(_vm._f("toMoney")(item.amount)) + " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }