function LastFourDigitsCreditCard(digits: string, type: string) {
  switch (type) {
    case 'american_express':
      return digits.replace(/(\d{4})(\d{7})(\d{4})/g, '$3');
    default:
      return digits.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/g, '$4');
  }
}

export default LastFourDigitsCreditCard;
