var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("HeaderSection", {
        ref: "HeaderSection",
        on: {
          handleChangeDate: function ($event) {
            return _vm.handleFilterBillsByPeriod($event)
          },
        },
      }),
      _vm._l(_vm.products, function (product, index) {
        return _c(
          "Accordion",
          { key: index, staticClass: "p-mt-4" },
          [
            _c(
              "AccordionTab",
              {
                attrs: { active: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("img", {
                            attrs: { src: product.image, alt: "Product" },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _c("business-group", {
                  attrs: { groups: product.groups },
                  on: {
                    "bills:invoice": function ($event) {
                      return _vm.getBills($event)
                    },
                    "bills:payment": function ($event) {
                      return _vm.makePayment($event)
                    },
                    pushGroup: function ($event) {
                      return _vm.onPage($event)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ companies, index: bIndex }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "p-mt-5" },
                              [
                                _c("billing-companies-tree", {
                                  attrs: {
                                    "total-items":
                                      companies.length > 25
                                        ? _vm.sizeTree[bIndex] || 25
                                        : companies.length,
                                  },
                                }),
                                _c("billing-companies-table", {
                                  staticClass: "ml-5",
                                  attrs: {
                                    id: `table-${bIndex}`,
                                    rows: companies,
                                    billid: _vm.billid,
                                  },
                                  on: {
                                    currentPage: function ($event) {
                                      return _vm.onPage(bIndex)
                                    },
                                    "billdetail:view": function ($event) {
                                      return _vm.viewDetail($event)
                                    },
                                    "billdetail:receipt": function ($event) {
                                      return _vm.showReceiptPayment($event)
                                    },
                                    "billdetail:add-summary": function (
                                      $event
                                    ) {
                                      return _vm.summaryForPayment($event, true)
                                    },
                                    "billdetail:remove-summary": function (
                                      $event
                                    ) {
                                      return _vm.summaryForPayment(
                                        $event,
                                        false
                                      )
                                    },
                                    "billdetail:change-all-summary": function (
                                      $event
                                    ) {
                                      return _vm.changeAllSummary($event)
                                    },
                                  },
                                }),
                                _c("billing-detail-modal", {
                                  key: _vm.modalKey,
                                  attrs: {
                                    show: _vm.displayModal,
                                    "billing-item": _vm.billingItem,
                                    "bill-products": _vm.billProducts,
                                  },
                                  on: {
                                    "update:show": [
                                      function ($event) {
                                        _vm.displayModal = $event
                                      },
                                      function ($event) {
                                        return _vm.updateModal($event)
                                      },
                                    ],
                                  },
                                }),
                                _c("ReceiptPaymentModal", {
                                  attrs: {
                                    show: _vm.displayReceiptPayment,
                                    charge: _vm.charge,
                                  },
                                  on: {
                                    "update:show": function ($event) {
                                      _vm.displayReceiptPayment = $event
                                    },
                                  },
                                }),
                                _vm.showSummary
                                  ? _c("billing-summary-for-payment", {
                                      attrs: {
                                        show: _vm.displayModal,
                                        payments: _vm.payments,
                                        "open-close-summary": _vm.closeSummary,
                                      },
                                      on: {
                                        "update:show": function ($event) {
                                          _vm.displayModal = $event
                                        },
                                        "billsummary:open-close": function (
                                          $event
                                        ) {
                                          return _vm.updateStatusSummary($event)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }