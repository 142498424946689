var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-2 pt-5 tree" },
    _vm._l(_vm.totalItems, function (item) {
      return _c("div", { key: item, staticClass: "tree-item" })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }