import axios from '@/common/http';

class PaymentMethodsService {
  private service: typeof axios = axios;

  private baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/api/v1/payment-methods`;

  public findPaymentMethod() {
    return this.service.get(this.baseUrl);
  }

  public createPaymentMethod(paymentMethod: any) {
    return this.service.post(this.baseUrl, paymentMethod);
  }

  public updatePaymentMethod(paymentMethod: any) {
    return this.service.put(this.baseUrl, paymentMethod);
  }

  public deletePaymentMethod(vindiId: number) {
    return this.service.delete(`${this.baseUrl}/${vindiId}`);
  }
}

export default new PaymentMethodsService();
