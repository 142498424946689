import NumberRemoveFormat from '@/common/filters/NumberRemoveFormat';
import { PaymentMethod } from '@/types';

/* eslint-disable */
export default ({ billing, invoice, paymentMethods }: PaymentMethod) => ({
  payment_methods: paymentMethods.map((card) => ({
    holder_name: card.name,
    card_expiration: card.expiresIn,
    card_number: NumberRemoveFormat(card.cardNumber),
    payment_company_code: card.flag,
    card_cvv: card.securityCode,
    main_card: card.isMain,
  })),
  bill_address: {
    company: billing.company,
    email: billing.email,
    street: billing.street,
    number: billing.number,
    additional_details: billing.additionalDetails,
    neighborhood: billing.neighborhood,
    city: billing.city,
    state: billing.state,
    country: billing.country,
    zipcode: NumberRemoveFormat(billing.zipCode),
  },
  invoice_address: {
    first_name: invoice.firstName ?? billing.company,
    last_name: invoice.lastName ?? '',
    street: invoice.street ?? billing.street,
    number: invoice.number ?? billing.number,
    additional_details: invoice.additionalDetails ?? billing.additionalDetails,
    neighborhood: invoice.neighborhood ?? billing.neighborhood,
    city: invoice.city ?? billing.city,
    state: invoice.state ?? billing.state,
    country: invoice.country ?? billing.country,
    zipcode: NumberRemoveFormat(billing.zipCode),
  },
});
