
import { mapMutations } from 'vuex';
import { showDateToUser } from '@roit/roit-date';
import moment from 'moment';
import { PropOptions } from 'vue';
import mixins from 'vue-typed-mixins';
import NotificationMixin from '@/mixins/NotificationMixin';
import SimpleTable from '@/common/components/table/SimpleTable.vue';
import StatusBilling from '@/resources/billing/components/StatusBilling.vue';
import CnpjFormat from '@/common/filters/CnpjFormat';
import {
  BillingItem, BillProducts, HeaderCustomTable,
} from '@/types';
import BillingService from '@/common/services/BillingService';

export default mixins(NotificationMixin).extend({
  name: 'billingItemModal',

  components: {
    CustomTable: SimpleTable,
    StatusBilling,
  },
  filters: {
    pointedValue(value: number): string {
      return value?.toString().replaceAll('.', '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') ?? value;
    },
    toMoney(value: string): string {
      if (value.includes('-')) {
        return value;
      }
      return parseFloat(value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
    periodRange(value: any | null): string {
      if (value) {
        const format = (date?: string): string | null => (date ? moment(date).format('DD/MM/YYYY') : null);
        return `${format(value?.start_at)} a ${format(value?.end_at)}`;
      }
      return value;
    },
    CnpjFormat,
  },
  props: {
    show: {
      type: Boolean as () => boolean,
      default: false,
    },
    billingItem: {
      type: Object as () => BillingItem,
      required: false,
      default: () => ({}),
    },
    billProducts: {
      type: Array as () => BillProducts[],
      required: false,
      default: () => ([]),
    } as PropOptions,
  },
  data() {
    return {
      header: {
        alias: [
          this.$t('billing.sections.billing.detailModal.table.product'),
          this.$t('billing.sections.billing.detailModal.table.consumed'),
          this.$t('billing.sections.billing.detailModal.table.price'),
          this.$t('billing.sections.billing.detailModal.table.surplus'),
          this.$t('billing.sections.billing.detailModal.table.minimumValue'),
          this.$t('billing.sections.billing.detailModal.table.total'),
        ],
        columnsToShow: [
          'product',
          'consumed',
          'price',
          'overage_price',
          'minimumValue',
          'amount',
        ],
      } as HeaderCustomTable,
      rows: this.billProducts as Array<BillProducts>,
    };
  },
  computed: {
    displayModal: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
    rowsData(): Array<BillProducts> {
      return this.rows;
    },
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    showISODate(date: string): string {
      const formated = showDateToUser(date);
      if (!formated) return date;
      return formated;
    },
    async makePayment(billItem: BillingItem): Promise<void> {
      try {
        this.handleLoading(true);
        const billId = billItem.id;
        const billet = (await BillingService.getBillet(billId)).data.data;

        if (billet.data) {
          window.open(billet.data, '_blank');
        } else {
          this.warningToast({
            title: this.$t('warning') as string,
            text: this.$t('billing.sections.billing.makePayment.msgWarning') as string,
          });
        }
      } catch (e) {
        this.errorToast({
          title: this.$t('error') as string,
          text: this.$t('billing.sections.billing.makePayment.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },
  },
});
