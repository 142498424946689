
import Vue from 'vue';
import PageCard from '@/common/template/PageCard.vue';

export default Vue.extend({
  name: 'BillingPage',
  components: {
    PageCard,
  },
  data: (vm: Vue) => ({
    items: [
      {
        label: vm.$t('billing.sections.billing.title'),
        to: {
          name: 'billing-index',
        },
      },
      {
        label: vm.$t('billing.sections.billingInformation.title'),
        to: {
          name: vm.$route.name === 'billing-information-manager' ? 'billing-information-manager' : 'billing-information',
        },
      },
    ],
  }),
});
