
import mixins from 'vue-typed-mixins';
import { mapGetters, mapMutations } from 'vuex';
import logoRoit from '@/common/assets/images/logo-roit-solutions-blue.svg';
import NotificationMixin from '@/mixins/NotificationMixin';
import BillingService from '@/common/services/BillingService';
import BillingCompaniesTable from '@/resources/billing/components/BillingCompaniesTable.vue';
import BillingCompaniesTree from '@/resources/billing/components/BillingCompaniesTree.vue';
import BillingDetailModal from '@/resources/billing/components/BillingDetailModal.vue';
import BillingSummaryForPayment from '@/resources/billing/components/BillingSummaryForPayment.vue';
import BusinessGroup from '@/resources/billing/components/BusinessGroup.vue';
import HeaderSection from '@/resources/billing/sections/HeaderSection.vue';
import ReceiptPaymentModal from '@/resources/billing/components/ReceiptPaymentModal.vue';

import {
  BillingCharge,
  BillingItem,
  BillingPeriod,
  BillProducts,
} from '@/types';

interface Products {
  [key: string]: {
    image: any;
    groups: Array<any>;
  };
}

export default mixins(NotificationMixin).extend({
  name: 'BillingSection',
  components: {
    HeaderSection,
    BusinessGroup,
    BillingCompaniesTable,
    BillingCompaniesTree,
    BillingDetailModal,
    BillingSummaryForPayment,
    ReceiptPaymentModal,
  },
  data: () => ({
    timeSizeTree: Object as any,
    sizeTree: [] as Array<number>,
    products: {
      bank: {
        image: logoRoit,
        groups: [],
      },
    } as Products,
    displayModal: false,
    displayReceiptPayment: false,
    modalKey: 0,
    billingPeriod: {} as BillingPeriod,
    billingItem: {} as BillingItem,
    billProducts: [] as BillProducts[],
    payments: [] as BillingItem[],
    charge: {} as BillingCharge,
    billid: 0,
    closeSummary: true,
    showSummary: false,
  }),
  mounted() {
    if (this.token && this.userLoggedIn) {
      this.listBills();
    }
    this.onPage(1);
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
    }),
  },
  watch: {
    token(token: string): void {
      if (token && this.userLoggedIn) {
        this.listBills();
      }
    },
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    async listBills(): Promise<void> {
      try {
        this.handleLoading(true);

        const response = (await BillingService.listBills()).data.data as any;

        Object.entries(response).forEach(([product, billings]) => {
          const isProductBank = product !== 'BANK';
          if (!isProductBank) {
            // @ts-ignore
            this.products[String(product).toLowerCase()].groups = billings;
          }
        });
      } catch (e) {
        this.errorToast({
          title: this.$t('error') as string,
          text: this.$t('billing.sections.billing.listBills.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    async makePayment(billId: number): Promise<void> {
      try {
        this.handleLoading(true);

        const billet = (await BillingService.getBillet(billId)).data.data;

        if (billet.data) {
          window.open(billet.data, '_blank');
        } else {
          this.warningToast({
            title: this.$t('warning') as string,
            text: this.$t('billing.sections.billing.makePayment.msgWarning') as string,
          });
        }
      } catch (e) {
        this.errorToast({
          title: this.$t('error') as string,
          text: this.$t('billing.sections.billing.makePayment.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    async getBills(billId: number): Promise<void> {
      try {
        this.handleLoading(true);
        const bill = (await BillingService.getBilling(billId)).data.data;

        if (bill.data) {
          window.open(bill.data, '_blank');
        } else {
          this.warningToast({
            title: this.$t('warning') as string,
            text: this.$t('billing.sections.billing.getBills.msgWarning') as string,
          });
        }
      } catch (e) {
        this.errorToast({
          title: this.$t('error') as string,
          text: this.$t('billing.sections.billing.getBills.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    async listBillsByPeriod(period: string): Promise<void> {
      try {
        this.handleLoading(true);

        this.products = {
          bank: {
            image: logoRoit,
            groups: [],
          },
        };

        const response = (await BillingService.listBillsByPeriod(period)).data.data as any;

        Object.entries(response).forEach(([product, billings]) => {
          // @ts-ignore
          (this.products[String(product).toLowerCase()].groups = billings);
        });
      } catch (e) {
        this.errorToast({
          title: this.$t('error') as string,
          text: this.$t('billing.sections.billing.listBillsByPeriod.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    handleFilterBillsByPeriod(period: string) {
      if (period) {
        return this.listBillsByPeriod(period);
      }
      return this.listBills();
    },

    async onPage(index: number): Promise<void> {
      await this.$nextTick();
      const table = document.querySelectorAll(`#table-${index} tbody tr`);
      this.sizeTree[index] = table.length;
      this.$forceUpdate();
    },

    async viewDetail(billItem: BillingItem): Promise<void> {
      this.billingItem = billItem;
      this.billProducts = await this.getBillProducts(billItem.id);
      this.showModal();
    },
    showModal(): void {
      this.modalKey += 1;
      this.displayModal = true;
    },
    async showReceiptPayment(billItem: BillingItem): Promise<void> {
      try {
        const charge = (await BillingService.getCharges(billItem.id)).data.data[0] as BillingCharge;
        if (!charge) {
          this.errorToast({
            title: this.$t('error') as string,
            text: this.$t('billing.sections.billing.receiptPayment.getChargeFail') as string,
          });
        } else {
          this.charge = charge;
          this.displayReceiptPayment = true;
        }
      } catch (_err) {
        this.errorToast({
          title: this.$t('error') as string,
          text: this.$t('billing.sections.billing.receiptPayment.getChargeFail') as string,
        });
      }
    },
    updateModal(action: boolean): void {
      this.displayModal = action;
    },
    summaryForPayment(billItem: BillingItem, addSummary: boolean): void {
      const [paymentUpdate] = this.payments.filter((p) => p.product === billItem.product);

      if (addSummary) {
        if (paymentUpdate) {
          if (paymentUpdate.id !== billItem.id) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            paymentUpdate.total_value = String(
              Number(paymentUpdate.total_value) + Number(billItem.total_value),
            );
          }
        } else {
          this.payments.push({ ...billItem });
        }
      } else if (paymentUpdate) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        paymentUpdate.total_value = String(
          Number(paymentUpdate.total_value) - Number(billItem.total_value),
        );
        if (Number(paymentUpdate.total_value) <= 0) {
          this.payments.splice(this.payments.indexOf(paymentUpdate), 1);
        }
      }

      this.showSummary = true;
      if (this.payments.length) {
        this.showSummary = true;
      } else {
        this.showSummary = false;
      }
      this.closeSummary = false;
    },
    updateStatusSummary(action: boolean): void {
      this.closeSummary = action;
    },
    async changeAllSummary(billItens: BillingItem[]): Promise<void> {
      if (billItens.length) {
        await billItens.forEach((i) => {
          this.summaryForPayment(i, true);
        });
      } else {
        this.payments = [];
        this.closeSummary = true;
        this.showSummary = false;
      }
    },

    async getBillProducts(billId: number): Promise<BillProducts[]> {
      const response = (await BillingService.getBillProducts(billId)).data.data as any;
      return response;
    },
  },
});
