/* eslint-disable @typescript-eslint/camelcase */
import { PaymentMethod } from '@/types';
import dateFormat from '@/common/filters/DateFormat';

interface PaymentMethodAdapter {
  id: string;
  userId: string;
  payment_methods: Array<Method>;
  bill_address: Billing;
  invoice_address: Invoice;
}

interface Common {
  street: string;
  number: string;
  additional_details: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
}

interface Method {
  holder_name: string;
  card_expiration: string;
  card_number: string;
  payment_company_code: string;
  card_cvv: string;
  main_card: boolean;
  vindi_id: number;
}

interface Billing extends Common {
  company: string;
  email: string;
}

interface Invoice extends Common {
  first_name: string;
  last_name: string;
}

export default ({
  id, userId, payment_methods, bill_address, invoice_address,
}: PaymentMethodAdapter): PaymentMethod => ({
  id,
  userId,
  paymentMethods: payment_methods.map((card: Method) => ({
    name: card.holder_name,
    expiresIn: dateFormat(card.card_expiration, { input: 'YYYY-MM-DD', output: 'MM/YYYY' }),
    cardNumber: card.card_number,
    flag: card.payment_company_code,
    securityCode: card.card_cvv,
    isMain: card.main_card,
    vindiId: card.vindi_id,
    showNumber: false,
  })),
  billing: {
    company: bill_address.company,
    email: bill_address.email,
    street: bill_address.street,
    number: bill_address.number,
    additionalDetails: bill_address.additional_details,
    neighborhood: bill_address.neighborhood,
    city: bill_address.city,
    state: bill_address.state,
    country: bill_address.country,
    zipCode: bill_address.zipcode,
  },
  invoice: {
    firstName: invoice_address.first_name,
    lastName: invoice_address.last_name,
    street: invoice_address.street,
    number: invoice_address.number,
    additionalDetails: invoice_address.additional_details,
    neighborhood: invoice_address.neighborhood,
    city: invoice_address.city,
    state: invoice_address.state,
    country: invoice_address.country,
    zipCode: invoice_address.zipcode,
  },
});
