var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "p-d-flex p-mt-5 p-jc-between" }, [
    _c("div", { staticClass: "grey300" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$t("billing.sections.billing.trackDetailedConsumption")) +
          " "
      ),
    ]),
    _c(
      "span",
      { staticClass: "p-input-icon-right" },
      [
        _c("input-validation", {
          attrs: { name: "dateCalendar", rules: "date:MM/YYYY", slim: "" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ errors }) {
                return [
                  _c("InputText", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "##/####",
                        expression: "'##/####'",
                      },
                    ],
                    ref: "period",
                    class: [{ "p-invalid": errors[0] }],
                    attrs: {
                      placeholder: _vm.$t(
                        "billing.sections.billing.filterByPeriod"
                      ),
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _c("i", { staticClass: "icon-calendar grey100" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }