
import Vue from 'vue';
import VueI18n from 'vue-i18n';

export default Vue.extend({
  name: 'BusinessGroup',
  filters: {
    normalizeName(group: string, defaultGroup: VueI18n.TranslateResult): string {
      return group === 'default' ? defaultGroup as string : group;
    },
  },
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    iconExpand: 'icon-r-plus',
    iconRetract: 'icon-r-less',
    expanded: [0] as Array<number>,
  }),
  methods: {
    toggleTableView(index: number) {
      const idx = this.expanded.findIndex((item: number) => item === index);
      if (idx !== -1) {
        this.expanded.splice(idx, 1);
      } else {
        this.expanded.push(index);
        this.$emit('pushGroup', index);
      }
    },
  },
});
