var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return Object.keys(_vm.method).length
    ? _c("div", { staticClass: "payment-card mb-md-4 mb-2" }, [
        _c("div", { staticClass: "w-100 text-right" }, [
          _c("img", {
            attrs: {
              src: require(`@/common/assets/images/${_vm.method.flag}_habilitado.svg`),
              alt: "flag",
            },
          }),
        ]),
        _c(
          "div",
          {
            staticClass:
              "my-2 text-white payment-card-name fs14 p-text-nowrap p-text-truncate p-mt-4",
          },
          [_vm._v(" " + _vm._s(_vm.method.name) + " ")]
        ),
        _c(
          "div",
          {
            staticClass:
              "my-2 text-white p-d-flex p-ai-center p-jc-between payment-card-number",
          },
          [
            _vm._l(3, function (group) {
              return _c(
                "span",
                { key: group, staticClass: "p-d-flex p-ai-center" },
                _vm._l(4, function (point) {
                  return _c("i", {
                    key: point,
                    staticClass: "pi pi-circle-on fs6 mr-1",
                  })
                }),
                0
              )
            }),
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("LastFourDigitsCreditCard")(
                    _vm.method.cardNumber,
                    _vm.method.flag
                  )
                ) +
                " "
            ),
          ],
          2
        ),
        _c("div", { staticClass: "fs12" }, [
          _c("span", { staticClass: "expires-label" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("billing.sections.billingInformation.expiresIn")
                ) +
                " "
            ),
          ]),
          _c(
            "span",
            { staticClass: "ml-2 fs13 text-white payment-card-expires" },
            [_vm._v(_vm._s(_vm.method.expiresIn))]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }