var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "PageCard",
    { staticClass: "billing-template" },
    [
      _c("template", { slot: "content" }, [
        _c(
          "div",
          [_c("TabMenu", { attrs: { model: _vm.items } }), _c("router-view")],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }