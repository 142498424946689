var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._l(_vm.groups, function (group, index) {
        return _c(
          "div",
          { key: group.companyGroup },
          [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("i", {
                class: [
                  "pointer",
                  "icon",
                  _vm.expanded.includes(index)
                    ? _vm.iconRetract
                    : _vm.iconExpand,
                ],
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleTableView(index)
                  },
                },
              }),
              _c("div", [
                _c("b", { staticClass: "p-ml-5 p-mr-2 fs16 secondary-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("normalizeName")(
                          group.companyGroup,
                          _vm.$t("billing.sections.billing.defaultGroupName")
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
              _vm._m(0, true),
            ]),
            _c(
              "transition",
              { attrs: { name: "fade" } },
              [
                _vm.expanded.includes(index)
                  ? _vm._t("default", null, {
                      companies: group.billItems,
                      billid: group.billId,
                      index: index,
                    })
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        )
      }),
      !_vm.groups.length
        ? _c("div", { staticClass: "grey300 p-text-center" }, [
            _c("img", {
              attrs: { src: require("@/common/assets/images/tabelaVazia.svg") },
            }),
            _c("p", [_vm._v(_vm._s(_vm.$t("table.mainLabelEmptyFlow")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("table.secondaryLabelEmptyFlow")))]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-grow-1" }, [
      _c("div", {
        staticStyle: { "border-top": "2px solid rgb(12, 82, 117)" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }