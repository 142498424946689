import axios from '@/common/http';

class StatesAndCitiesService {
    private service: typeof axios = axios;

    private baseUrl = `${process.env.VUE_APP_STATES_CITIES_BASE_URL}/api/v1`;

    public getStates() {
      return this.service.get(`${this.baseUrl}/states`);
    }

    public getStateCities(stateCode: number) {
      return this.service.get(`${this.baseUrl}/states/${stateCode}/cities`);
    }
}
export default new StatesAndCitiesService();
