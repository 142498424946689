// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../common/assets/images/bg_arredondado_cartao_de_credito.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".payment-card[data-v-5d4f610f] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center;\n  width: 100%;\n  width: 260px;\n  height: 160px;\n  padding: 20px 24px;\n}\n.payment-card .payment-card-name[data-v-5d4f610f] {\n  font-family: 'SF Pro Display';\n}\n.payment-card .payment-card-number[data-v-5d4f610f] {\n  font: normal normal normal 16px/19px 'SF Pro Display';\n}\n.payment-card .payment-card-expires[data-v-5d4f610f] {\n  font-family: 'SF Pro Display';\n}\n.payment-card .expires-label[data-v-5d4f610f] {\n  color: rgba(255, 255, 255, 0.33);\n}\n", ""]);
// Exports
module.exports = exports;
