
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';
import { showDateToUser } from '@roit/roit-date';
import { BillingItem, HeaderCustomTable } from '@/types';
import Table from '@/common/components/table/Table.vue';
import BillingService from '@/common/services/BillingService';
import NotificationMixin from '@/mixins/NotificationMixin';

export default mixins(NotificationMixin).extend({
  name: 'BillingItemModal',

  components: {
    CustomTable: Table,
  },
  filters: {
    pointedValue(value: number): string {
      return value?.toString().replaceAll('.', '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') ?? value;
    },
    toMoney(value: string): string {
      return parseFloat(value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
  },
  props: {
    openCloseSummary: {
      type: Boolean as () => boolean,
      default: true,
    },
    payments: {
      type: Array as () => BillingItem[],
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      header: {
        alias: [
          this.$t('billing.sections.billing.businessGroupTable.headers.product'),
          this.$t('billing.sections.billing.detailModal.table.total'),
        ],
        columnsToShow: [
          'product',
          'total',
        ],
      } as HeaderCustomTable,
      rows: this.payments as BillingItem[],
      closeSummary: this.openCloseSummary,
      optionsPayment: [
        this.$t('billing.sections.billing.summaryPayment.payByInvoice'),
        this.$t('billing.sections.billing.summaryPayment.payByCreditCard'),
      ],
      paymentMethod: '',
      disablePayment: true,
    };
  },
  computed: {
    rowsData(): BillingItem[] {
      return this.rows;
    },
    totalPaymentSummary(): number {
      let totalPayment = 0;
      if (this.rows) {
        this.rows.forEach((r) => {
          totalPayment += Number(r.total_value);
        });
      }
      return totalPayment;
    },
  },
  watch: {
    openCloseSummary: {
      immediate: true,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      handler(val, oldVal): void {
        this.closeSummary = val as boolean;
      },
    },
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    showISODate(date: string): string {
      const formated = showDateToUser(date);
      if (!formated) return date;
      return formated;
    },
    changeValue(): void {
      this.closeSummary = !this.closeSummary;
      this.$emit('billsummary:open-close', this.closeSummary);
    },
    ablePayment(): void {
      this.disablePayment = true; // TODO: wait definition of address payment (INT-90)
    },
    async makePayment(): Promise<void> {
      try {
        this.handleLoading(true);
        const billId: number = this.rows[0].id;
        const billet = (await BillingService.getBillet(billId)).data.data;

        if (billet.data) {
          window.open(billet.data, '_blank');
        } else {
          this.warningToast({
            title: this.$t('warning') as string,
            text: this.$t('billing.sections.billing.makePayment.msgWarning') as string,
          });
        }
      } catch (e) {
        this.errorToast({
          title: this.$t('error') as string,
          text: this.$t('billing.sections.billing.makePayment.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },
  },
});
